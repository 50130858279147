import React from "react"
import getName from "./getName"
import blockMap from "../components/blocks"
import Hero from "../components/Hero/Hero"
import CtaStrip from "../components/CTAStrip/CTAStrip"
import StandardText from "../components/StandardText/StandardText"

// Skeleton
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import HeroForm from "../components/HeroForm"
import DownloadHero from "../components/DownloadHero"

const BlockReturn = ({ blocks }) => {
  const isSSR = typeof window === "undefined"
  return blocks?.map((block, index) => {
    // assign component math with name
    const Component = blockMap[getName(block?.name)]
    if (block.name === "acf/hero") {
      return <Hero key={`${block?.name}-${index}`} {...block} />
    }
    if (block.name === "acf/cta-strip") {
      return <CtaStrip key={`${block?.name}-${index}`} {...block} />
    }
    if (block.name === "acf/standardtext") {
      return <StandardText key={`${block?.name}-${index}`} {...block} />
    }

    if (block.name === "acf/hero-form") {
      return <HeroForm key={`${block?.name}-${index}`} {...block} />
    }
    if (block.name === "acf/download-hero") {
      return <DownloadHero key={`${block?.name}-${index}`} {...block} />
    }
    return (
      !isSSR && (
        <React.Suspense
          fallback={
            <div className="container w-sreen h-screen text-center">
              <Skeleton count={12} />
            </div>
          }
        >
          <Component key={`${block?.name}-${index}`} {...block} />
        </React.Suspense>
      )
    )
  })
}

export default BlockReturn
