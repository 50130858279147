import React, { useEffect, useState } from "react"
import "../HeroForm/style.scss"
import { Link } from "gatsby"
import Button from "../Button/Button"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const DownloadHero = ({ downloadHero }) => {
  const { backgroundImage, cta, description, title, files } = downloadHero

  const image = getImage(backgroundImage?.localFile)
  const bgImage = convertToBgImage(image)

  const [formId, setFormId] = useState(null)
  const [fileUrl, setFileUrl] = useState(null)

  useEffect(() => {
    // Retrieve formId from local storage
    const storedFormId = localStorage.getItem("formId")

    if (storedFormId) {
      setFormId(storedFormId)

      // Find the matching file based on formId
      const matchedFile = files.find(item => item.formId === storedFormId)
      if (matchedFile) {
        setFileUrl(matchedFile.file.mediaItemUrl)
      }
    }
  }, [files]) // Re-run if files change

  return (
    <BackgroundImage
      {...bgImage}
      Tag="div"
      className="hero_form__wrapper thank-you"
    >
      <div className="container">
        <div className="hero_form__section">
          <div className="hero_form__section__content">
            <div className="hero_form__section__content__title">
              <h1 style={{ color: "#53bbad" }}>
                {title || "Thank You for Your Submission!"}
              </h1>
            </div>
            <div>
              <p>
                {description ||
                  "We appreciate you reaching out. Our team will review your request and contact you soon with the information you need."}
              </p>
            </div>
            <div className="hero_form__section__content__cta">
              {cta?.map(({ button }, index) => {
                if (!button) return null
                return (
                  <div
                    className="btn custom-btn"
                    key={`ty-hero-button-${index}`}
                  >
                    <Link
                      to={button?.url}
                      target={button?.target}
                      style={{ backgroundColor: "#8C1A5E", color: "#fff" }}
                    >
                      {button?.title}
                    </Link>
                  </div>
                )
              })}
              {fileUrl && (
                <Button
                  key="2"
                  button={{
                    text: "Download Guide",
                    url: fileUrl,
                    target: "_blank",
                    type: "white",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default DownloadHero
