import React from "react"
import "./style.scss"
import HTMLReactParser from "html-react-parser"
import { graphql, useStaticQuery } from "gatsby"
import GravityFormForm from "gatsby-plugin-gravity-forms"
import Button from "../Button/Button"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const HeroForm = ({ heroForm }) => {
  const {
    gfAcfPicker,
    formDescription,
    formTitle,
    heroBackground,
    heroTitle,
    heroContent,
    cta,
    note,
    redirect,
    redirectLink,
  } = heroForm || {}

  const settings = useStaticQuery(graphql`
    query {
      wp {
        ...GravityFormSettings
      }
    }
  `)

  const formData = { wp: settings?.wp, wpGfForm: gfAcfPicker }

  const titleForm = formTitle || gfAcfPicker?.title
  const descriptionForm = formDescription || gfAcfPicker?.description
  const image = getImage(heroBackground?.localFile)
  const bgImage = convertToBgImage(image)

  const afterSubmit = () => {
    if (gfAcfPicker?.databaseId) {
      localStorage.setItem("formId", gfAcfPicker.databaseId)
    }
    if (redirect && redirectLink?.uri) {
      window.location.href = redirectLink.uri
    }
  }

  return (
    <BackgroundImage {...bgImage} Tag="div" className="hero_form__wrapper">
      <div className="container">
        <div className="hero_form__section">
          <div className="hero_form__section__content">
            <div className="hero_form__section__content__title">
              {HTMLReactParser(heroTitle || "")}
            </div>
            <div>{HTMLReactParser(heroContent || "")}</div>
            <div className="hero_form__section__content__cta">
              {cta?.map((item, index) => (
                <Button
                  key={`cta-button-${index}`}
                  button={{
                    className:
                      item.buttonType === "secondary" ? "custom-btn btn" : "",
                    text: item.button?.title,
                    url: item.button?.url,
                    target: item.button?.target,
                    type: item.buttonType || "secondary",
                  }}
                />
              ))}
            </div>
          </div>
          <div className="hero_form__section__form">
            {titleForm && (
              <div className="hero_form__section__form__title">{titleForm}</div>
            )}
            {descriptionForm && (
              <p className="hero_form__section__form__content">
                {descriptionForm}
              </p>
            )}
            <GravityFormForm
              data={formData}
              errorCallback={e => console.error("Form Error:", e)}
              successCallback={() => {
                console.log("Form submitted successfully")
                afterSubmit()
              }}
            />
            {note && <p className="hero_form__section__form__note">{note}</p>}
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default HeroForm
