import { Link } from "gatsby"
import React from "react"
import "./button.scss"
import { PropTypes } from "prop-types"
Button.propTypes = {
  button: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
    type: PropTypes.oneOf(["primary", "secondary", "white"]),
  }),
}
function Button({ button }) {
  return (
    <div className={`button ${button?.type} ${button.className}`}>
      {button?.text && (
        <Link to={button?.url} target={button?.target}>
          {button?.text}
        </Link>
      )}
    </div>
  )
}

export default Button
